<template>
<NavBar :sessionKey="sessionKey" :released="released" />
<VideoPlayerModal v-if="showModalVideo" :video="videoToPlay" @closeModal="toggleVideoModal()" />
<div class="home">
	<section v-if="!released" id="temporal">
		<div class="container-fluid px-md-5 px-3">
			<div class="row ms-md-5">
				<div class="col-md-6 position-relative pt-5 offset-md-1">
					<h1 class="text-size-95 canela fw-100">Green <br><span class="orange-text">Diploma</span></h1>
					<p class="text-size-24 canela fw-normal fst-italic">L'approccio di Davide Oldani <br> alla sostenibilità in cucina</p>
					<p class="canela mt-4 me-md-5 text-size-18 fst-italic fw-thin">
						5 masterclass<br>
						Scopri come sviluppare un progetto di ristorazione sostenibile
					</p>
					<img class="bg" src="@/assets/img/home/bg-hero.png">
					<img class="oldani" src="@/assets/img/home/hero.png">
				</div> 
				<div class="col-md-5">
					<div class="content h-100 bg-orange p-md-5 p-3">
						<p class="text-center text-size-56 canela fst-italic">Non perdertelo!</p>
						<p class="subtitle text-center text-uppercase text-white">La prima masterclass inizierà tra</p>
						<div class="row mx-0 timer">
							<div class="col-3 text-center">
								<p class="value helvetica text-size-46 text-white">{{ days }}</p>
								<p class="tag">giorni</p>
							</div>
							<div class="col-3 text-center">
								<p class="value helvetica text-size-46 text-white">{{ hours }}</p>
								<p class="tag">ore</p>
							</div>
							<div class="col-3 text-center">
								<p class="value helvetica text-size-46 text-white">{{ minutes }}</p>
								<p class="tag ">min</p>
							</div>
							<div class="col-3 text-center">
								<p class="value helvetica text-size-46 text-white">{{ seconds }}</p>
								<p class="tag">sec</p>
							</div>
						</div>
						<p class="text-center text-white fw-normal my-3 mx-md-4">Il primo capitolo del corso sarà disponibile lunedì 21 marzo</p>
						<form class="form" v-on:submit.prevent=register()>
							<div class="row gx-4 gy-2">
								<div class="col-md-6">
									<input required class="field" type="text" v-model="first_name" placeholder="Nome*">
								</div>
								
								<div class="col-md-6">
									<input required class="field" type="text" v-model="last_name" placeholder="Cogname*">
								</div>
								<div class="col-md-6">
									<input required class="field" type="email" v-model="email" placeholder="E-mail">
								</div>
								<div class="col-md-6">
									<select required class="field-select field" name="select" v-model="profession">
										<option value="" disabled>Sono...*</option>
										<optgroup label="Professional horeca">
											<option value="chef">Chef</option>
											<option value="pasticcere">Pasticcere</option>
											<option value="gelatiere">Gelatiere</option>
										</optgroup>
										<optgroup label="Professional horeca">
											<option value="ristorante">Ristorante</option>
											<option value="pasticceria/panetteria">Pasticceria/Panetteria</option>
											<option value="gelateria">Gelateria</option>
											<option value="enoteca/vineria">Enoteca/Vineria</option>
											<option value="macelleria">Macelleria</option>
											<option value="other">Other</option>
										</optgroup>
										<option value="privato">Privato</option>
										<option value="studente">Studente</option>
										<option value="altro">Altro</option>
									</select>
								</div>
								<div class="col-md-12">
									<input required class="field" type="password" v-model="password" placeholder="Password">
								</div>
								<div class="col-12 py-0">
									<div class="form-check mt-0">
										<input required type="checkbox" class="form-check-input" />
										<label class="form-check-label" for="group_2">Accetto di ricevere comunicazioni di carattere commerciale*</label>
									</div>
									<div class="form-check mt-0">
										<input required type="checkbox" class="form-check-input" />
										<label class="form-check-label" for="group_1">Accetto la  <a href="https://www.versusprojects.com/it/privacy"><u>Privacy Policy</u></a>*</label>
									</div>
								</div>
								<div class="col-md-12">
									<button class="orange-cta ms-0 me-auto border-0 mt-2" type="submit">Iscriviti</button>
								</div>
							</div>

						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section v-if="released" id="hero">
		<div class="container">
			<div class="row">
				<div class="col-md-4 position-relative">
					<h1 class="text-size-95 canela fw-100">Green <br><span class="orange-text">Diploma</span></h1>
					<p class="text-size-24 canela fw-normal fst-italic">L'approccio di Davide Oldani <br> alla sostenibilità i cucina</p>
					<p class="canela mt-4 me-md-5 text-size-18 fst-italic fw-thin">
						5 masterclass<br>
						Scopri come sviluppare un progetto di ristorazione sostenibile
					</p>
					<router-link v-if="!sessionKey" to="/register">
						<button class="orange-cta fw-500 ms-0 me-auto mt-5">
							Inizia ora
						</button>
					</router-link>
					<a v-if="sessionKey" href="#videos">
						<button class="orange-cta fw-500 ms-0 me-auto mt-5">
							Inizia ora
						</button>
					</a>
				</div>
				<div class="col-md-4 position-relative">
					<img v-on:click="toggleVideoModal('introduction')" src="@/assets/img/home/hero.png" class="main-img">
				</div>
				<div class="col-md-4 position-relative"></div>
			</div>
		</div>
	</section>
	<section id="davide" class="bg-grey almost-white-text">
		<div class="container">
			<div class="row">
				<div class="col-md-7">
					<div class="row">
						<div class="col-md-6 d-flex flex-column justify-content-end" style="z-index:1">
							<p class="text-size-32 canela fw-100" style="text-shadow: 2px 2px 15px #000;">
								“Questo percorso mi permetterà di condividere con voi la mia esperienza e i miei consigli per sviluppare un progetto di ristorazione sostenibile”
							</p>
							<p class="subtitle orange-text">2 Stelle Michelin<br>Stella Verde Michelin</p>
						</div>
						<div class="col-md-6">
							<img src="@/assets/img/home/davide.jpg">
						</div>
					</div>
				</div>
				<div class="col-md-4 offset-md-1 mt-5 mt-md-0 d-flex flex-column justify-content-center">
					<h2 class="orange-text text-size-80">Davide Oldani</h2>
					<p class="fw-300 text-light-grey">Chef italiano patron del ristorante D'O a San Pietro all'Olmo (Milano, Italia), con 2 Stelle Michelin e Stella Verde Michelin per la Gastronomia Sostenibile. Definisce la sua cucina Cucina Pop. Davide è stato invitato ad Harvard per raccontare la sua esperienza imprenditoriale in seguito a una case history sul suo ristorante pubblicata sulla Harvard Business School Review e ora condivide la sua esperienza anche attraverso il Green Diploma.</p>
					<p class="subtitle text-white text-uppercase">
						2 Stelle Michelin<br>
						Stella Verde Michelin <br>
						Ideatore della Cucina POP <br>
						Masterclass alla Harvard Business School 
					</p>
				</div>
			</div>
		</div>
	</section>
	<section id="contents" class="bg-grey almost-white-text">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<p class="text-size-95 canela fw-100 mb-5">Contenuti del corso</p>
				</div>
				<div class="col-md-6 pe-5">
					<img class="w-100" src="@/assets/img/home/course-content.jpg">
				</div>
				<div class="col-md-6">
					<p class="mt-md-0 mt-5">Il Green Diploma è un percorso che permetterà di condividere con voi l'esperienza dello chef Davide Oldani, con l'intento di fornire una lettura che possa permettere di sviluppare con successo un modello di cucina sostenibile. Davide Oldani ha ricevuto stelle Michelin e una stella verde Michelin verde, come riconoscimento al suo impegno per la sostenibilità.</p>
					<p>Attraverso 5 capitoli, Davide Oldani condividerà le 5 chiavi che lo hanno portato a costruire un progetto di cucina sostenibile riconosciuto dalla guida Michelin. I 5 capitoli parleranno dell'origine degli ingredienti, dell'utilizzo dei prodotti di stagione, dell'impatto ambientale del ristorante, della tecnologia e del coinvolgimento con la comunità locale. </p>
					<div class="row">
						<div class="col-sm-6">
							<img src="@/assets/img/home/chapters.svg" class="me-3">
							<p class="small-subtitle">5 Capitoli</p>
						</div>
						<div class="col-sm-6">
							<img src="@/assets/img/home/certificate.svg" class="me-3">
							<p class="small-subtitle">Attestato Finale</p>
						</div>
						<div class="col-sm-6">
							<img src="@/assets/img/home/duration.svg" class="me-3">
							<p class="small-subtitle">da 5 a 12 minuti</p>
						</div>
						<div class="col-sm-6">
							<img src="@/assets/img/home/free.svg" class="me-3">
							<p class="small-subtitle">100% Gratuito</p>
						</div>
					</div>
					<a v-if="!released" class="orange-cta w-fit-content mt-5 ms-0" href="#temporal">inizia il corso</a>
					<router-link v-if="released && !sessionKey" class="orange-cta w-fit-content mt-5 ms-0" to="/register">inizia il corso</router-link>
				</div>
			</div>
		</div>
	</section>
	<section v-if="released" id="videos" class="bg-grey almost-white-text">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="accordion accordion-flush" id="videoAccordion">
						<div v-for="chapter in chapters" v-bind:key="chapter.id" class="accordion-item">
							<h2 class="accordion-header" :id="'flush-heading' + chapter.id">
								<button class="accordion-button collapsed" type="button" :data-bs-toggle="chapter.is_active ? 'collapse' : '' " :data-bs-target="'#flush-collapse' + chapter.id" :aria-controls="'flush-collapse' + chapter.id">
									<p class="text-size-28 color-white canela fw-normal text-white">{{ chapter.title }} <span v-if="!chapter.is_active">- In arrivo</span></p>
									
								</button>
							</h2>
							<div :id="'flush-collapse' + chapter.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + chapter.id" data-bs-parent="#videoAccordion">
							<div class="accordion-body row">
								<div class="col-md-7">
									<p class="light-grey-text text-size-15 fw-light helvetica" v-html="chapter.description"></p>
									<p class="text-size-21 almost-white-text canela fw-100 fst-italic" v-if="chapter.learnings">Cosa imparerai?</p>
									<ul class="light-grey-text text-size-15 fw-light helvetica" v-html="chapter.learnings"></ul>
									<div class="row my-5 justify-content-start align-items-start">
										<router-link class="w-fit-content" :to="'/capitolo/' + chapter.id"><button class="white-border-cta w-fit-content mx-md-0 ms-3">Scoprire il capitolo</button></router-link>
										<button v-on:click="toggleVideoChapterModal('MC' + chapter.id)" class="white-cta w-fit-content ms-md-4 ms-3 mt-3 mt-md-0">Guarda ora<i class="play-now"></i></button>
									</div>
								</div>
								<div class="col-md-5">
									<img src="@/assets/img/chapter-cover.jpg" class="w-100">
								</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section v-if="released" id="banner" class="bg-orange">
		<div class="container">
			<div class="row">
				<div class="col-12 d-flex align-items-center flex-column text-center">
					<p class="canela fw-100 text-size-56 text-white">Inizia il <br><span class="text-black text-uppercase text-size-95">corso</span></p>
					<img v-on:click="toggleVideoModal('introduction')" class="mx-auto cursor-pointer" src="@/assets/img/home/footer.png">
					<p class="decorator canela fst-italic fw-thin ">Green Diploma<br><span class="text-size-56 fw-normal">e ottieni il certificato di riconoscimento</span></p>
				</div>
			</div>
		</div>
	</section>
</div>
<FooterLinks />
<FormMessageModal v-if="viewMessageModal" @closeModal="toggleMessageModal()" :message="modalMessage" :id="modalId" />

</template>

<script>

import NavBar from "@/components/NavBar.vue"
import FooterLinks from "@/components/FooterLinks.vue"
import FormMessageModal from "@/components/FormMessageModal.vue"
import VideoPlayerModal from "@/components/VideoPlayerModal.vue"
import axios from "axios"

export default {
name: "Home",
	data() {
		return {
			chapters: [],
			showModalVideo: false,
			videoToPlay: '',
			sessionKey: null,
			releaseDate: new Date("March 21, 2022 08:00:00").getTime(),
			today: new Date().getTime(),
			days: '',
			hours: '',
			minutes: '',
			seconds: '',

			first_name: '',
			last_name: '',
			email: '',
			password: '',
			profession: '',

			released: true,

			viewMessageModal: false,
            modalMessage: '',
			modalId: '',
			currentTime: '',
		}
	},


	
	components: { VideoPlayerModal, NavBar, FormMessageModal, FooterLinks },
	
	mounted() {
		if (this.$route.query.key) {
			var key = this.$route.query.key
			this.validateEmail(key)
		}
		setInterval(() => {
			this.getCountdown()
		}, 1000)
		this.getChapters()
		this.sessionKey = this.getCookie()
	},
	methods: {
		getCountdown() {
			this.today = new Date().getTime()
			var distance = this.releaseDate - this.today
			// Time calculations for days, hours, minutes and seconds
			this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
			this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			this.seconds = Math.floor((distance % (1000 * 60)) / 1000);


		},

		validateEmail(key) {
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/registration/verify-email/"
			var data = {
				'key': key,
			}
			axios
				.post(url, data)
				.then(() => (
					this.modalMessage = "Grazie, abbiamo verificato il tuo account. Buon divertimento!",
					this.modalId = "idemailconfirmed",
					this.toggleMessageModal()
				));
		},

		getChapters() {
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/"
			axios
				.get(url)
				.then((response) => (
					this.chapters = response.data
				));
		},

		toggleVideoModal(video) {
			if (video) {
				this.videoToPlay = video
			}
			this.showModalVideo = !this.showModalVideo
		},

		toggleVideoChapterModal(video) {
			if (this.sessionKey) {
				if (video) {
					this.videoToPlay = video
				}
				this.showModalVideo = !this.showModalVideo
			}

			if (!this.sessionKey) {
				this.$router.push("/register")
			}
		},

		getCookie() {
			var nameEQ = "sessionCookie" + "=";
			var ca = document.cookie.split(';');
			for(var i=0;i < ca.length;i++) {
				var c = ca[i];
				while (c.charAt(0)==' ') c = c.substring(1,c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
			}
			return null;
		},

		register() {
			var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/registration/"
			var data = {
				'first_name': this.first_name,
				'last_name': this.last_name,
				'email': this.email,
				'profession': this.profession,
				'password1': this.password,
				'password2': this.password
			}
			axios
				.post(url, data)
				.then((response) => (
					this.modalMessage = "Grazie, ti abbiamo inviato un'email di conferma per verificare il tuo account. Per favore controlla la tua casella di posta",
					this.toggleMessageModal(),
					this.modalId = "idstart",
					this.setCookie("sessionCookie",response.data.key,90)
				));
		},
		setCookie(name,value,days) {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "")  + expires + "; path=/";
		},

		toggleMessageModal() {
			this.viewMessageModal = !this.viewMessageModal
		}
	}

};
</script>

<style lang="sass" scoped>

#temporal
	background-color: #f6f6f6
	padding-top: 20px
	padding-bottom: 100px
	div.col-md-6:first-child
		margin-top: 40px
		position: relative
		@media (max-width: 768px)
			margin-top: 20px
		img.bg
			top: 0
			display: inline-block
			margin-top: 0px
			width: 80%
			margin-left: auto
			margin-right: 0
			@media (max-width: 768px)
				width: 100%

		img.oldani
			position: absolute
			width: 40%
			left: 10vw
			bottom: 80px
			@media (max-width: 768px)
				width: 60%
				bottom: 20px
				left: 20vw

	h1
		font-size: 126px
		z-index: 1
		position: relative
		@media (max-width: 768px)
			margin-top: 30px
			font-size: 68px
			line-height: 1

	p
		z-index: 2
		position: relative

	.timer
		border: 1px solid #fff
		.value
			text-transform: uppercase
			font-weight: bold
			margin-bottom: 0
			line-height: 1
		.tag
			text-align: center
			color: #fff
			margin-top: 0
			text-transform: uppercase
			font-family: "Helvetica Neue" sans-serif
			font-size: 9px
			letter-spacing: 2px

	form
		position: relative
		@media (max-width: 768px)
			padding-right: 0px

		p
			font-size: 25px

		input.field
			width: 100%
			border-radius: 0
			font-size: 15px
			background: transparent
			border: none
			border-bottom: 1px solid
			color: #f6f6f6
			border-color: #f6f6f6
			padding-left: 0
			margin-bottom: 10px

			&::placeholder
				font-size: 12px
				letter-spacing: 0.1vw
				color: #f6f6f6
				text-transform: uppercase

		label
			font-size: 11px

		.form-check:first-child
			margin-top: 10px

		input[type="checkbox"]
			border-color: #333
			border-radius: 50px

		input.form-check-input
			border-radius: 50px
			border-color: #333
			width: 0.75em
			height: 0.75em
			margin-top: 0.25em
			vertical-align: top
			background-color: #fff
			background-repeat: no-repeat
			background-position: center
			background-size: contain
			border: 1px solid rgba(0, 0, 0, 0.25)
			margin-right: 5px
		
		.form-check-input:checked[type=checkbox]:checked
			background-color: #B17643 !important

		select:not([multiple])
			width: 100%
			font-size: 12px
			letter-spacing: 0.1vw
			color: #646464
			text-transform: uppercase
			-webkit-appearance: none
			-moz-appearance: none
			background-position: right 50%
			background-repeat: no-repeat
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=)
			padding: .5em
			padding-right: 1.5em

		button
			margin-top: 0
			background-color: #fff
			color: #B17643
			width: 100%

	
#hero
	background-color: #f6f6f6
	padding-top: 160px
	padding-bottom: 100px
	background-image: url("~@/assets/img/home/bg-hero.png")
	background-size: 50%
	background-position: center right
	background-repeat: no-repeat
	@media (max-width: 768px)
		padding-top: 100px
		background-position: center bottom
		background-size: 100%
	h1
		font-size: 126px
		z-index: 1
		position: relative
		@media (max-width: 768px)
			font-size: 70px
		

	p
		z-index: 2
		position: relative

	.decorator
		font-size: 80px
		color: #B17643
		opacity: 0.33
		z-index: 1
		position: relative
		white-space: nowrap

	img.main-img
		position: relative
		left: -25%
		width: 50vw
		max-height: 70vh
		object-fit: contain
		object-position: center
		z-index: 0
		cursor: pointer
		@media (max-width: 768px)
			left: 20px
			top: 50px


#davide
	padding-top: 150px
	padding-bottom: 150px
	@media (max-width: 768px)
		padding-top: 70px
		padding-bottom: 70px
	img
		margin-left: -33%
		width: 133%
		@media (max-width: 768px)
			margin-left: 0
			width: 100%
	.subtitle
		font-size: 12px
		line-height: 2
		@media (max-width: 768px)
			white-space: inherit

#contents
	padding-top: 50px
	padding-bottom: 100px
	.col-sm-6
		display: flex
		flex-direction: row
		margin-top: 20px
		img
			width: 15px
			height: 15px
			object-fit: contain
			object-position: center left

		p
			margin: 0
			white-space: nowrap

	
#videos
	padding-bottom: 100px
	.accordion
		.accordion-item
			border-color: #dedede
		.accordion-button::after
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important

		.accordion-item, .accordion-button
			background-color: transparent
			p::v-deep strong
				font-weight: bold
				color: #fff

	@media (max-width: 768px)
		.accordion-body, .accordion-item, .accordion-button
			padding-left: 0
			padding-right: 0

#banner
	padding-top: 100px
	padding-bottom: 100px
	.decorator
		font-size: 180px
		color: rgba(255,255,255,0.33)
		z-index: 1
		position: relative
		white-space: nowrap
		margin-top: -130px
		line-height: 140px
		@media (max-width: 768px)
			font-size: 60px
			white-space: normal
		span
			display: block
			opacity: 1 !important
			color: #333

	img
		position: relative
		width: 50vw
		margin-left: -12.5vw
		max-height: 70vh
		object-fit: contain
		object-position: center
		z-index: 0
			

</style>

