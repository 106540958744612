import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";


import "../node_modules/jquery/dist/jquery.min.js"
import "../node_modules/bootstrap/js/src/collapse.js";

import "./assets/sass/custom-bootstrap.scss";
import "./assets/sass/styles.sass";

// import "https://code.jquery.com/jquery-3.3.1.slim.min.js"


createApp(App).use(router).mount("#app");
