<template>
    <nav>
        <div class="container-fluid px-5 d-none d-md-block">
            <div class="row position-relative">
                <a href="https://versusprojects.com/it/">
                    <img src="@/assets/img/versus-logo.svg" class="position-absolute">
                </a>
                <div class="col-6 d-flex align-items-center">
                    <a href="https://versusprojects.com/it/vetrine-refrigerate/" class="nav-link">PRODOTTI</a>
                    <a href="https://versusprojects.com/it/sostenibilita/" class="nav-link">SOSTENIBILITÀ</a>
                    <a href="https://versusprojects.com/it/chi-siamo/" class="nav-link">CHI SIAMO</a>
                    <a href="https://versusprojects.com/it/custom-made/" class="nav-link">PROGETTI SU MISURA</a>
                </div>
                <div class="col-6 d-flex flex-row justify-content-end" v-if="released">
                    <!-- <button v-if="sessionKey" class="btn btn-white"><router-link to="/account">Il mio account</router-link></button> -->
                    <router-link to="/login"><button v-if="!sessionKey" class="btn btn-white">ACCEDI</button></router-link>
                    <router-link to="/register"><button v-if="!sessionKey" class="btn btn-black">Iscriviti</button></router-link>
                    <a href="" v-on:click="logOut()"><button v-if="sessionKey" class="btn btn-black">Esci</button></a>
                </div>
            </div>
        </div>
        <div class="container-fluid px-5 d-block d-md-none">
            <div class="row position-relative text-center">
                <a href="https://versusprojects.com" target="_blank">
                    <img src="@/assets/img/versus-logo.svg" class="position-absolute">
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
    import axios from "axios"

    export default {
        name: "Home",
        props: ['sessionKey', 'released'],

        methods: {
            logOut() {
                this.requestLogOut()
                this.deleteCookie("sessionCookie")
            },

            requestLogOut() {
                this.errorList = []
                var url = process.env.VUE_APP_API_DOMAIN + "api/v1/rest-auth/logout/"
                var headers =  {
                    'Authorization': 'Token ' + this.sessionKey,
                    'Content-Type': 'application/json'
                }
                axios
                    .get(url, { headers: headers })
                    .then((response) => (
                        console.log(response)
                    ))
                    .catch(function(error) {
                        console.log(error)
                    })
            },

            deleteCookie(name) {
                document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
        }
    }
</script>

<style lang="sass" scoped>
nav
    position: absolute
    width: 100%
    display: block
    padding-top: 20px
    padding-block-end: 20px
    .nav-link
        margin-right: 40px
        font-family: "Helvetica"
        font-size: 12px
        font-weight: 300
        text-transform: uppercase
        letter-spacing: 2px
    img
        width: 150px
        left: calc(50% - 75px)
        top: 2px

    .btn
        border-radius: 0
        padding: 16px 28px 14px
        border: 1px solid
        font-family: "Helvetica"
        font-size: 12px
        font-weight: 500
        letter-spacing: 2px
        margin-left: 20px
        text-transform: uppercase
        &.btn-black
            background-color: #000
            color: #fff
            border-color: #000
        &.white
            border-color: #000
            background-color: #fff
            color: #000

</style>
