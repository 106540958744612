<template>
  <router-view />
</template>

<style lang="sass">
@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Thin.woff") format("woff"), url("/fonts/Canela-Thin.woff2") format("woff2"), url("/fonts/Canela-Thin.ttf") format("ttf")
	font-style: normal
	font-weight: 100

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Thin-Italic.woff") format("woff"), url("/fonts/Canela-Thin-Italic.woff2") format("woff2"), url("/fonts/Canela-Thin-Italic.ttf") format("ttf")
	font-style: italic
	font-weight: 100

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Light.woff") format("woff"), url("/fonts/Canela-Light.woff2") format("woff2"), url("/fonts/Canela-Light.ttf") format("ttf")
	font-style: normal
	font-weight: 300

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Regular.woff") format("woff"), url("/fonts/Canela-Regular.woff2") format("woff2"), url("/fonts/Canela-Regular.ttf") format("ttf")
	font-style: normal
	font-weight: 400

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Regular-Italic.woff") format("woff"), url("/fonts/Canela-Regular-Italic.woff2") format("woff2"), url("/fonts/Canela-Regular-Italic.ttf") format("ttf")
	font-style: italic
	font-weight: 400

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Medium.woff") format("woff"), url("/fonts/Canela-Medium.woff2") format("woff2"), url("/fonts/Canela-Medium.ttf") format("ttf")
	font-style: normal
	font-weight: 500

@font-face
	font-family: "Canela"
	src: url("/fonts/Canela-Bold.woff") format("woff"), url("/fonts/Canela-Bold.woff2") format("woff2"), url("/fonts/Canela-Bold.ttf") format("ttf")
	font-style: normal
	font-weight: bold

/** Font Helvetica Neue **/

@font-face
	font-family: "Helvetica Neue"
	src: url("/fonts/HelveticaNeue-Thin.woff") format("woff"), url("/fonts/HelveticaNeue-Thin.woff2") format("woff2"), url("/fonts/HelveticaNeue-Thin.ttt") format("ttf")
	font-style: normal
	font-weight: 100

@font-face
	font-family: "Helvetica Neue"
	src: url("/fonts/HelveticaNeue-Light.woff") format("woff"), url("/fonts/HelveticaNeue-Light.woff2") format("woff2"), url("/fonts/HelveticaNeue-Light.ttt") format("ttf")
	font-style: normal
	font-weight: 300

@font-face
	font-family: "Helvetica Neue"
	src: url("/fonts/HelveticaNeue-Regular.woff") format("woff"), url("/fonts/HelveticaNeue-Regular.woff2") format("woff2"), url("/fonts/HelveticaNeue-Regular.ttt") format("ttf")
	font-style: normal
	font-weight: 400

@font-face
	font-family: "Helvetica Neue"
	src: url("/fonts/HelveticaNeue-Medium.woff") format("woff"), url("/fonts/HelveticaNeue-Medium.woff2") format("woff2"), url("/fonts/HelveticaNeue-Medium.ttt") format("ttf")
	font-style: normal
	font-weight: 500

@font-face
	font-family: "Helvetica Neue"
	src: url("/fonts/HelveticaNeue-Bold.woff") format("woff"), url("/fonts/HelveticaNeue-Bold.woff2") format("woff2"), url("/fonts/HelveticaNeue-Bold.ttt") format("ttf")
	font-style: normal
	font-weight: bold

#app
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	text-align: left

</style>
