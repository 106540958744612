<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-on:click="closeModal()">
            <div :id="id" class="modal" v-on:click.stop=""  role="dialog"  aria-labelledby="modalTitle"  aria-describedby="modalDescription">
                <header  class="modal-header"  id="modalTitle">
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">
                        x
                    </button>
                </header>

                <div class="modal-body" id="modalDescription">
                    <div class="row">
                        <div class="col-12">
                           <p v-if="message">{{ message }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
	export default {
		name: "FormMessageModal",

        props: ["message", "id"],

		emits: ["closeModal"],

        methods: {
            closeModal() {
                this.$emit("closeModal")
            },
        }
    }
</script>



<style lang="sass" scoped>
.modal-backdrop
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.25)
    display: flex
    justify-content: center
    align-items: center
    z-index: 999


.modal 
    background: #f7f7f7
    overflow-x: auto
    display: flex
    flex-direction: column
    min-width: 500px
    max-width: 50vw
    min-height: 0
    height: auto
    padding: 50px 40px
    @media (max-width: 700px)
        min-width: 100%
        height: 100%



.modal-header,
.modal-footer 
    padding: 0
    display: flex


.modal-header 
    position: relative
    justify-content: space-between


.modal-footer 
    border-top: 1px solid #eeeeee
    flex-direction: column


.modal-body 
    position: relative  

.btn-close 
    position: absolute
    top: -40px
    right: -20px
    border: none
    font-size: 40px
    padding: 10px
    cursor: pointer
    font-weight: 100
    color: #B17643
    background: transparent



.modal-fade-enter,
.modal-fade-leave-to 
    opacity: 0


.modal-fade-enter-active,
.modal-fade-leave-active 
    transition: opacity .5s ease


</style>