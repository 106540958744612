<template>
<footer class="py-4">
	<div class="container-fluid px-5">
	<div class="row row-small-links flex-column-reverse flex-md-row">
		<div class="col col-left d-flex justify-content-start align-items-end pb-1">
		<a class="small-footer-element fw-light me-5" href="https://versusprojects.com/it/cookies">Cookies</a>
		<a class="small-footer-element fw-light" href="https://versusprojects.com/it/privacy">Privacy</a>
		</div>
		<div class="col d-none d-md-flex justify-content-center align-items-end">
			<a href="https://versusprojects.com">
				<img class="logo" src="@/assets/img/versus-logo-white.svg" />
			</a>
		</div>
		<div class="col px-0 px-md-2 mb-5 mb-md-0 col-left d-flex justify-content-center justify-content-md-end align-items-center">
		<a href="https://www.instagram.com/versus.italy/" target="_blank"><img class="social" src="@/assets/img/instagram.svg" /></a>
		<a href="https://www.linkedin.com/company/versus-italy/" target="_blank"><img class="social" src="@/assets/img/linkedin.svg" /></a>
		<a href="https://www.facebook.com/Versus.italy/" target="_blank"><img class="social" src="@/assets/img/facebook.svg" /></a>
		</div>
	</div>
	</div>
</footer>
</template>

<script>
export default {
name: "FooterLinks",

methods: {
	closeModal() {
	this.$emit("clickOnClose", false);
	},
	toggleSubmenu() {
	var element = document.getElementsByClassName("collapsed-menu")[0];
	if (element.classList.contains("h-auto")) {
		element.classList.remove("h-auto");
	} else {
		element.classList.add("h-auto");
	}
	},
},
};
</script>

<style scoped lang="sass">
footer
	background-color: #333
	color: #D8D7D8
	.collapsed-menu
		max-height: 0
		overflow: hidden
		transition: max-height 0.5s ease
		.col-4
			display: flex
			justify-content: center


	.collapsed-menu.h-auto
		max-height: 500px
		transition: max-height 0.5s ease

	.links-row
		padding-top: 120px
		padding-bottom: 80px
		@media (max-width: 768px)
			padding-bottom: 60px
			padding-top: 90px
		.footer-element
			font-size: 32px
			margin-bottom: 0px
			font-family: "Canela", sans-serif
			font-weight: 100

	.row-small-links
		padding-bottom: 30px

		.small-footer-element
			text-transform: uppercase
			font-size: 12px
			font-family: "Helvetica Neue", sans-serif
			letter-spacing: 2px
			@media (max-width: 768px)
				letter-spacing: 0.4px
				font-size: 11px
		.social
			margin-left: 20px
			@media (max-width: 768px)
				width: 60px
				margin-left: 7px
				margin-right: 7px
</style>
